import React from "react";

const BadgeCheck = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 18.9996C38 16.3143 36.6383 13.9472 34.5673 12.5507C35.0439 10.0997 34.333 7.46346 32.4346 5.56346C30.5362 3.66505 27.8999 2.95413 25.4473 3.43071C24.0508 1.35971 21.6837 -0.00195312 18.9984 -0.00195312C16.3131 -0.00195312 13.946 1.35971 12.5495 3.43071C10.0985 2.95413 7.46066 3.66505 5.56224 5.56346C3.66383 7.46188 2.95291 10.0981 3.42949 12.5507C1.35849 13.9472 -0.00317383 16.3143 -0.00317383 18.9996C-0.00317383 21.685 1.35849 24.052 3.42949 25.4485C2.95291 27.8995 3.66383 30.5374 5.56224 32.4358C7.46066 34.3342 10.0969 35.0451 12.5495 34.5685C13.946 36.6395 16.3131 38.0012 18.9984 38.0012C21.6837 38.0012 24.0508 36.6395 25.4473 34.5685C27.8983 35.0451 30.5362 34.3342 32.4346 32.4358C34.333 30.5374 35.0439 27.9011 34.5673 25.4485C36.6383 24.052 38 21.685 38 18.9996ZM18.8559 24.413C18.2432 25.0258 17.4372 25.3314 16.6282 25.3314C15.8191 25.3314 15.0052 25.0226 14.3862 24.4051L9.98133 20.1365L12.1869 17.8612L16.6092 22.1473L25.8067 13.1207L28.0297 15.377L18.8559 24.413Z"
        fill="#69C9B7"
      />
    </svg>
  );
};

export default BadgeCheck;
