import React from "react";
import clsx from "clsx";
import styles from "./style/earlyAccess.module.scss";

const EarlyAccess = ({ children }) => {
  return (
    <div className={clsx(styles.main)}>
      {children}
      <div className={clsx(styles.right)}></div>
    </div>
  );
};

export default EarlyAccess;
