import React, { useState } from "react";
import clsx from "clsx";
import styles from "./style/earlyAccess.module.scss";
import signupStyles from "./style/signup.module.scss";
import Logo from "../../component/svg/Logo";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import NameInput from "../../component/Input/NameInput";
import EmailIcon from "../../component/svg/EmailIcon";
import BookADemo from "../../component/Button/BookADemo";
import EarlyAccess from "./EarlyAccess";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import LockIcon from "../../component/svg/LockIcon";
import GreenCheckbox from "../../component/Checkbox/GreenCheckbox";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("* This field is mandatory"),
});

const signupFormData = {
  email: "",
};

const RecoverPassword = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  //create account
  const handleCreateAccountFun = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    const { email } = values;

    // setLoader(true)
    const submitData = {
      email: email,
    };
    // navigate("/thankyou"); //    console.log("submitData", submitData);
    // if (false) {
    //   toast.error("Something went wrong!");
    // }
  };

  return (
    <section>
      <EarlyAccess>
        <div className={clsx(styles.div1)}>
          <div>
            <Logo />
          </div>
          {/* form section */}
          <div className={clsx(styles.mainDiv)}>
            <div className={clsx(styles.formDiv)}>
              <h2>Recover Password</h2>
              <p>
                Please enter your email address to receive a link to reset your
                password.
              </p>
              <Formik
                initialValues={signupFormData}
                validationSchema={() => {
                  return validationSchema;
                }}
                enableReinitialize={true}
                onSubmit={handleCreateAccountFun}
              >
                {(formik) => (
                  <form
                    className={clsx(styles.form)}
                    onSubmit={formik?.handleSubmit}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <NameInput
                        type={"email"}
                        placeholder={""}
                        value={formik?.values?.email}
                        name={"email"}
                        formik={formik}
                        icon={<EmailIcon />}
                        label={"Email address*"}
                        touched={formik.touched.email}
                        errors={formik.errors.email}
                      />
                    </div>

                    <div className={clsx(styles.bookDemo)}>
                      <BookADemo
                        label="Email me a recovery link"
                        color={"#FFFFFF"}
                        bg={"#164DFF"}
                        borderRadius={"4px"}
                        padding={"16px 26px"}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        hoverBgColor={"login"}
                        width={"100%"}
                        type="submit"
                      />
                    </div>
                    <div className={clsx(signupStyles.alreadyDiv)}>
                      <p className={clsx(styles)}>
                        Remember your password?{" "}
                        <span
                          onClick={() => {
                            navigate("/login");
                          }}
                          className={clsx(styles.footerLink)}
                        >
                          Log in
                        </span>
                      </p>
                    </div>
                    <div className={clsx(styles.footerPara, styles.footer)}>
                      <p>
                        Simplified AI for Integrations and Enterprise Workflow
                        Automation
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </EarlyAccess>
    </section>
  );
};

export default RecoverPassword;
