import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
   name: 'authSlice',
   initialState: {
      user: "hello"
   },
   reducers: {
      setUser: (state, actions) => {
         state.user = actions.payload;
      },
   },

});

export const { setUser } = authSlice.actions;


export default authSlice.reducer;