import React, { useState } from "react";
import clsx from "clsx";
import styles from "./style/earlyAccess.module.scss";
import signupStyles from "./style/signup.module.scss";
import Logo from "../../component/svg/Logo";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import NameInput from "../../component/Input/NameInput";
import EmailIcon from "../../component/svg/EmailIcon";
import BookADemo from "../../component/Button/BookADemo";
import EarlyAccess from "./EarlyAccess";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import LockIcon from "../../component/svg/LockIcon";
import GreenCheckbox from "../../component/Checkbox/GreenCheckbox";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("* This field is mandatory"),
  password: Yup.string()
    .required("* This field is mandatory")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    ),
});

const signupFormData = {
  name: "",
  email: "",
  password: "",
  rememberMe: false,
};

const Signup = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  //create account
  const handleCreateAccountFun = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    const { name, email, password, rememberMe } = values;

    // setLoader(true)
    const submitData = {
      name: name,
      email: email,
      password: password,
      rememberMe: rememberMe,
    };
    // navigate("/thankyou"); //    console.log("submitData", submitData);
    // if (false) {
    //   toast.error("Something went wrong!");
    // }
  };

  return (
    <section>
      <EarlyAccess>
        <div className={clsx(styles.div1)}>
          <div>
            <Logo />
          </div>
          {/* form section */}
          <div className={clsx(styles.mainDiv)}>
            <div className={clsx(styles.formDiv)}>
              <h2>Create an Account</h2>
              <p>We're excited to have you here.</p>
              <Formik
                initialValues={signupFormData}
                validationSchema={() => {
                  return validationSchema;
                }}
                enableReinitialize={true}
                onSubmit={handleCreateAccountFun}
              >
                {(formik) => (
                  <form
                    className={clsx(styles.form)}
                    onSubmit={formik?.handleSubmit}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <NameInput
                        type={"text"}
                        placeholder={""}
                        value={formik?.values?.name}
                        name={"name"}
                        formik={formik}
                        icon={false}
                        label={"Name"}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                      />
                    </div>
                    <div>
                      <NameInput
                        type={"email"}
                        placeholder={""}
                        value={formik?.values?.email}
                        name={"email"}
                        formik={formik}
                        icon={<EmailIcon />}
                        label={"Email address*"}
                        touched={formik.touched.email}
                        errors={formik.errors.email}
                      />
                    </div>
                    <div className={clsx(styles.inputField)}>
                      <NameInput
                        type={"password"}
                        placeholder={""}
                        value={formik?.values?.password}
                        name={"password"}
                        formik={formik}
                        icon={<LockIcon />}
                        label={"Password*"}
                        touched={formik.touched.password}
                        errors={formik.errors.password}
                      />
                    </div>
                    <div className={clsx(signupStyles.checkBoxMainDiv)}>
                      <div className={clsx(signupStyles.check)}>
                        <GreenCheckbox
                          id={"rememberMe"}
                          checkboxName="rememberMe" // Set the name to match your form structure
                          formik={formik}
                          value={formik?.values?.rememberMe}
                          touchedName={formik.touched.rememberMe}
                          errorName={formik.errors.rememberMe}
                        />
                        <label>Remember me</label>
                      </div>
                    </div>
                    <div className={clsx(styles.bookDemo)}>
                      <BookADemo
                        label="Proceed"
                        color={"#FFFFFF"}
                        bg={"#164DFF"}
                        borderRadius={"4px"}
                        padding={"16px 26px"}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        hoverBgColor={"login"}
                        width={"100%"}
                      />
                    </div>
                    <div className={clsx(signupStyles.alreadyDiv)}>
                      <p className={clsx(styles)}>
                        Already have an account?{" "}
                        <span
                          onClick={() => {
                            navigate("/login");
                          }}
                          className={clsx(styles.footerLink)}
                        >
                          Log In
                        </span>
                      </p>
                    </div>
                    <div className={clsx(styles.footerPara, styles.footer)}>
                      <p>
                        Simplified AI for Integrations and Enterprise Workflow
                        Automation
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </EarlyAccess>
    </section>
  );
};

export default Signup;
