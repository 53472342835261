import React from "react";
import EarlyAccess from "../EarlyAccess/EarlyAccess";
import Logo from "../../component/svg/Logo";
import earlyStyle from "../EarlyAccess/style/earlyAccess.module.scss";
import clsx from "clsx";
import styles from "./style/thankyou.module.scss";
import BadgeCheck from "../../component/svg/BadgeCheck";
const Thankyou = () => {
  return (
    <section>
      <EarlyAccess>
        <div className={clsx(earlyStyle.div1)}>
          <div>
            <Logo />
          </div>
          <div>
            <div className={clsx(styles.mainDiv)}>
              <div>
                <div className={clsx(styles.thankyou)}>
                  <BadgeCheck />
                  <h1>Thank you! </h1>
                  <p>
                    We’ve sent you an acknowledgement, we’ll be releasing our
                    beta version soon to limited users and you will be notified
                    of the same.
                  </p>
                </div>
              </div>
              <div className={clsx(earlyStyle.footerPara, styles.footer)}>
                <p>
                  Simplified AI for Integrations and Enterprise Workflow
                  Automation
                </p>
              </div>
            </div>
          </div>
        </div>
      </EarlyAccess>
    </section>
  );
};

export default Thankyou;
