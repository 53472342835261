import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import Early from './pages/EarlyAccess/Early';
import Thankyou from './pages/Thankyou/Thankyou';
import Signup from './pages/EarlyAccess/Signup';
import Login from './pages/EarlyAccess/Login';
import RecoverPassword from './pages/EarlyAccess/RecoverPassword';
// import EarlyAccess from './pages/EarlyAccess/EarlyAccess';

const App = () => {
  // console.log('App.js')
  return (
    <React.Fragment>
      <Routes>
        {/* Auth Pages */}
        <Route path="/early-access" element={<Early />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
      </Routes>

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        containerClassName="toastContainer toaster-wrapper123"
        toastOptions={{
          className: 'defaulterrormessage',
          duration: 6000,
          success: {
            duration: 5000,
          }
        }}
      />
    </React.Fragment>
  )
}

export default App;