import React from "react";
import clsx from "clsx";
import styles from "./style/nameInput.module.scss";

const NameInput = ({
  label,
  type,
  placeholder = "",
  value,
  name,
  formik,
  icon = false,
  touched = false,
  errors = false,
}) => {
  return (
    <div className={clsx(styles.inputField)}>
      {label && <label>{label}</label>}
      <div
        className={clsx(
          "",
          styles.emailInputDiv,
          touched && errors ? "createPasswordRed" : ""
        )}
      >
        {icon && icon}
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>
      {touched && errors ? (
        <div
          className={clsx("mt-3 text-[#ca0700] text-[12px]", styles.errorDiv)}
        >
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default NameInput;
