import authSlice from './slices/authSlice';
import persistConfig from './persistConfig';

import {
   persistStore,
   persistReducer,
   // FLUSH,
   // REHYDRATE,
   // PAUSE,
   // PERSIST,
   // PURGE,
   // REGISTER,
} from "redux-persist";
import { combineReducers, configureStore } from '@reduxjs/toolkit';


const rootReducer = combineReducers({
   authSlice: authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) => {
      return (
         getDefaultMiddleware({
            serializableCheck: false,
         })
      )
   }
});

export let persistor = persistStore(store);
