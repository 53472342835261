import React, { useState } from "react";
import clsx from "clsx";
import styles from "./style/earlyAccess.module.scss";
import Logo from "../../component/svg/Logo";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import NameInput from "../../component/Input/NameInput";
import EmailIcon from "../../component/svg/EmailIcon";
import BookADemo from "../../component/Button/BookADemo";
import EarlyAccess from "./EarlyAccess";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "libphonenumber-js";
import NewPhoneInput from "../../component/Input/NewPhoneInput";
import { postMethod } from "../../utils/apiServices";
// import NewPhoneInput from "../../component/Input/NewPhoneInput";
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("* This field is mandatory")
    .matches(/^[A-Za-z\s]+$/, "Full name must contain only letters and spaces"),
  companyName: Yup.string()
    .required("* This field is mandatory")
    .matches(
      /^[A-Za-z\s]+$/,
      "company name must contain only letters and spaces"
    ),
  email: Yup.string()
    .email("Invalid email format")
    .required("* This field is mandatory"),
  phone: Yup.string()
    .required("* This field is mandatory")
    .matches(
      /^\+?[0-9]+$/,
      "Phone number must contain only digits and can start with an optional '+'"
    )
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number must be less than 15 digits"),
});

const signupFormData = {
  fullName: "",
  companyName: "",
  email: "",
  phone: "",
};

const Early = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  //create account
  const handleCreateAccountFun = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    const { companyName, email, phone, fullName } = values;
    // Parse the phone number
    const phoneNumberObj = parsePhoneNumber(phone);

    // Extract country code and phone number
    const countryCode = phoneNumberObj?.countryCallingCode;
    const phoneNumber = phoneNumberObj?.nationalNumber;
    // "companyName":"testing",
    const submitData = {
      fullName: fullName,
      companyName: companyName,
      email: email,
      phoneNumber: phoneNumber,
      countryCode: `+${countryCode}`,
    };
    await postMethod(`early-access/add`, submitData).then((response) => {
      if (response?.success) {
        toast.success(response?.message);
        resetForm();
        navigate("/thankyou");
      } else {
        toast.error(response?.message);
      }
    });
  };

  return (
    <section>
      <EarlyAccess>
        <div className={clsx(styles.div1)}>
          <div>
            <Logo />
          </div>
          {/* form section */}
          <div className={clsx(styles.mainDiv)}>
            <div className={clsx(styles.formDiv)}>
              <h2>Early Access </h2>
              <p>We're excited to have you here.</p>
              <Formik
                initialValues={signupFormData}
                validationSchema={() => {
                  return validationSchema;
                }}
                enableReinitialize={true}
                onSubmit={handleCreateAccountFun}
              >
                {(formik) => (
                  <form
                    className={clsx(styles.form)}
                    onSubmit={formik?.handleSubmit}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <NameInput
                        type={"text"}
                        placeholder={""}
                        value={formik?.values?.fullName}
                        name={"fullName"}
                        formik={formik}
                        icon={false}
                        label={"Full name*"}
                        touched={formik.touched.fullName}
                        errors={formik.errors.fullName}
                      />
                    </div>
                    <div>
                      <NameInput
                        type={"text"}
                        placeholder={""}
                        value={formik?.values?.companyName}
                        name={"companyName"}
                        formik={formik}
                        icon={false}
                        label={"Company name*"}
                        touched={formik.touched.companyName}
                        errors={formik.errors.companyName}
                      />
                    </div>

                    <div>
                      <NameInput
                        type={"email"}
                        placeholder={""}
                        value={formik?.values?.email}
                        name={"email"}
                        formik={formik}
                        icon={<EmailIcon />}
                        label={"Email address*"}
                        touched={formik.touched.email}
                        errors={formik.errors.email}
                      />
                    </div>
                    <div>
                      <NewPhoneInput
                        label={"Phone number*"}
                        type={"text"}
                        placeholder={""}
                        value={formik?.values?.phone}
                        name={"phone"}
                        formik={formik}
                        icon={false}
                        touched={formik.touched.phone}
                        errors={formik.errors.phone}
                      />
                    </div>
                    <div className={clsx(styles.bookDemo)}>
                      <BookADemo
                        label="Register for Early Access"
                        color={"#FFFFFF"}
                        bg={"#164DFF"}
                        borderRadius={"4px"}
                        padding={"16px 26px"}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        hoverBgColor={"login"}
                        width={"100%"}
                        //   type="submit"
                      />
                    </div>
                    <div className={clsx(styles.footerPara, styles.footer)}>
                      <p>
                        Simplified AI for Integrations and Enterprise Workflow
                        Automation
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </EarlyAccess>
    </section>
  );
};

export default Early;
