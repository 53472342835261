import React from "react";
import clsx from "clsx";
import styles from "./style/nameInput.module.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const NewPhoneInput = ({
  type,
  placeholder = "",
  value,
  name,
  formik,
  touched = false,
  errors = false,
  label,
}) => {
  return (
    <div className={clsx("", styles.inputField)}>
      <label>{label}</label>
      <div
        className={clsx(
          "",
          styles.emailInputDiv,
          touched && errors ? "createPasswordRed" : ""
        )}
      >
        <PhoneInput
          className={clsx(styles.phoneInput)}
          country="US"
          defaultCountry="US"
          value={value}
          placeholder={placeholder}
          onChange={(phone) => formik?.setValues({ ...formik?.values, phone })}
        />
      </div>
      {touched && errors ? (
        <div
          className={clsx("mt-3 text-[#ca0700] text-[12px]", styles.errorDiv)}
        >
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default NewPhoneInput;
