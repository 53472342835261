import clsx from "clsx";
import React from "react";
import styles from "./style/bookADemo.module.scss";
const BookADemo = ({
  type = "button",
  hoverBgColor = false,
  width = false,
  maxWidth = false,
  label,
  color,
  bg = false,
  borderRadius = false,
  padding = false,
  fontWeight,
  fontSize,
  disabled = false,
}) => {
  return (
    <button
      teyp={type}
      disabled={disabled}
      style={{
        color: color || "white",
        backgroundColor: bg || "#164DFF",
        borderRadius: borderRadius || "4px",
        padding: padding || "14px 35px",
        fontWeight: fontWeight || "600",
        fontSize: fontSize || "16px",
        maxWidth: maxWidth || "auto",
        width: width || "auto",
      }}
      className={clsx(!disabled && styles.blueHover, styles.btn)}
    >
      {label}
    </button>
  );
};

export default BookADemo;
